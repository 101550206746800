import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
export const UserURL = `${API_URL}/company_users/`
export const RoleURL = `${API_URL}/roles/`
export const PermissionURL = `${API_URL}/permissions/`
export const CompanyURL = `${API_URL}/companies/`
export function getCompanyUsersApi(page: any, filter?: any) {
  return axios.get(UserURL+`paginate?page=${page}&perPage=100`)
}
export function getCompanyUserApi(data: any) {
  return axios.get<any>(UserURL+data.id)
}
export function editCompanyUser(data: any) {
  return axios.patch<any>(UserURL+data.id, data)
}

export function getRolesApi() {
  return axios.get(RoleURL)
}
export function getCompaniesApi(page: any, filter?: any) {
  if(!filter)
  return axios.get(CompanyURL+`paginate?page=${page}&perPage=20`)
  else
  return axios.get(CompanyURL+`paginate?page=${page}&perPage=20&filter=${filter}`)
}
export function getCompanyApi(id: any) {
  return axios.get(CompanyURL+id)
}
export function editCompanyApi(data: any) {
  return axios.patch<any>(CompanyURL+data.id, data)
}
export function deleteCompanyApi(id: any) {
  return axios.delete<any>(CompanyURL+id)
}
export function getPermissionsApi() {
  return axios.get(PermissionURL)
}
export function getRoleApi(id: any) {
  return axios.get<any>(RoleURL+id)
}
export function editRole(data: any) {
  return axios.patch<any>(RoleURL+data.id, data)
}
export function createRole(data: any) {
  return axios.post<any>(RoleURL, data)
}
export function deleteRole(id: any) {
  return axios.delete<any>(RoleURL+id)
}