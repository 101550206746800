import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import * as redux from '../../redux/DataRedux'
import {useHistory, useParams} from 'react-router-dom'
export function EditCountry(props: any) {
  const dispatch = useDispatch()
  const history = useHistory()
  const data: any = useSelector<RootState>(({data}) => data.country)
  const params: any = useParams()
  useEffect(() => {
    dispatch(redux.actions.getCountry({id: params.id}))
  }, [])
  const handleSubmit = (values: any, e: any) => {
    e.preventDefault()
    dispatch(redux.actions.editCountry(values, history))
  }
  return data && data.name ? (
    <>
      <Formik
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(true)
        }}
        initialValues={{
          id: data ? data.id || null : null,
          name: data ? data.name || '' : '',
          name_ar: data ? data.name_ar || '' : '',
          name_fr: data ? data.name_fr || '' : '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
        })}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleReset,
          setSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <form className='form'>
            <div className='w-100'>
              {/*begin::Heading */}
              {/*begin::Heading */}

              {/*begin::Form Group */}
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Country Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name'
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Country Arabic Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name_ar'
                  value={values.name_ar}
                  onChange={handleChange}
                />
              </div>

              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Country French Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name_fr'
                  value={values.name_fr}
                  onChange={handleChange}
                />
              </div>
            </div>

            <button
              className='btn btn-primary'
              style={{backgroundColor: '#87e8c5'}}
              onClick={(e) => {
                setSubmitting(true)
                handleSubmit(values, e)
              }}
              type='submit'
            >
              Submit
            </button>
          </form>
        )}
      </Formik>
    </>
  ) : (
    <></>
  )
}
