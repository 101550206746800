import React from 'react'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {useDispatch} from 'react-redux'
import { useHistory } from "react-router-dom";
import * as redux from '../../redux/DataRedux'
export function CreateContractType(props :any)  {
  const dispatch = useDispatch()
  const history = useHistory()
  const handleSubmit = (values : any, e: any) => {
    e.preventDefault()
    dispatch(redux.actions.createData(values,history))
  }
  return (
    <>
      <Formik
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(true)
        }}
        initialValues={{
          name: '',
          name_ar: '',
name_fr: '',
          type: 'contractType'
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required')
        })}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleReset,
          setSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <form className='form'>
            <div className='w-100'>
              {/*begin::Heading */}
              {/*begin::Heading */}

              {/*begin::Form Group */}
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Contract Type Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name'
                  placeholder=''
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>
                  Contract Type Arabic Name
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name_ar'
                  value={values.name_ar}
                  onChange={handleChange}
                />
              </div>
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>
                Contract Type French Name
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name_fr'
                  value={values.name_fr}
                  onChange={handleChange}
                />
              </div>
            </div>

            <button className ="btn btn-primary" onClick={((e)=>{
          setSubmitting(true); handleSubmit(values,e)})}style={{backgroundColor: '#87e8c5'}} type='submit'>Submit</button>
          </form>
        )}
      </Formik>
    </>
  )
}
