import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest, select, takeEvery} from 'redux-saga/effects'
import {createRole, deleteRole, editRole, editCompanyUser, getPermissionsApi, getRoleApi, getRolesApi, getCompanyUserApi, getCompanyUsersApi, deleteCompanyApi, editCompanyApi, getCompaniesApi, getCompanyApi} from "./service";
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  getCompanyUsers: '[getCompanyUsers] Action',
  getCompanyUsersSucceded: '[getCompanyUsersSucceded] Action',
  getCompanyUsersFailed: '[getCompanyUsersFailed] Action',
  getCompanyUser: '[getCompanyUser] Action',
  getCompanyUserSucceded: '[getCompanyUserSucceded] Action',
  getCompanyUserFailed: '[getCompanyUserFailed] Action',
  editCompanyUser: '[editCompanyUser] Action',
  editCompanyUserSucceded: '[editCompanyUserSucceded] Action',
  editCompanyUserFailed: '[editCompanyUserFailed] Action' ,
  getPermissions: '[getPermissions] Action',
  getPermissionsSucceded: '[getPermissionsSucceded] Action',
  getPermissionsFailed: '[getPermissionsFailed] Action',
  getRoles: '[getRoles] Action',
  getRolesSucceded: '[getRolesSucceded] Action',
  getRolesFailed: '[getRolesFailed] Action',
  getRole: '[getRole] Action',
  getRoleSucceded: '[getRolesucceded] Action',
  getRoleFailed: '[getRoleFailed] Action',
  createRole: '[createRole] Action',
  editRole: '[editRole] Action',
  editRoleSucceded: '[editRoleSucceded] Action',
  editRoleFailed: '[editRoleFailed] Action',
  createRoleSucceded: '[createRoleSucceded] Action',
  createRoleFailed: '[createRoleFailed] Action',
  deleteRole: '[deleteRole] Action',
  deleteRoleSucceded: '[deleteRoleSucceded] Action',
  deleteRoleFailed: '[deleteRoleFailed] Action',
  getCompanies: '[getCompanies] Action',
  getCompaniesSucceded: '[getCompaniesSucceded] Action',
  getCompaniesFailed: '[getCompaniesFailed] Action',
  getCompany: '[getCompany] Action',
  getCompanySucceded: '[getCompaniesucceded] Action',
  getCompanyFailed: '[getCompanyFailed] Action',
  createCompany: '[createCompany] Action',
  editCompany: '[editCompany] Action',
  editCompanySucceded: '[editCompanySucceded] Action',
  editCompanyFailed: '[editCompanyFailed] Action',
  createCompanySucceded: '[createCompanySucceded] Action',
  createCompanyFailed: '[createCompanyFailed] Action',
  deleteCompany: '[deleteCompany] Action',
  deleteCompanySucceded: '[deleteCompanySucceded] Action',
  deleteCompanyFailed: '[deleteCompanyFailed] Action',
}

const initialCategoriesState: any = {
  current_page : null,
  first_page : null,
  last_page : null,
  usersList : [],
  companiesList : [],
  rolesList : [],
  permissionsList : [],
  role : {},
  companyUser : {},
  company : {}
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken']},
  (state: any = initialCategoriesState, action: ActionWithPayload<any>) => {
    switch (action.type) {
      case actionTypes.getCompanyUsersSucceded: {
        const usersList = action.payload?.data
        return {...state, usersList, first_page: action.payload?.meta?.first_page, last_page: action.payload?.meta?.last_page, current_page: action.payload?.meta?.current_page}
      }
      case actionTypes.getCompanyUserSucceded: {
        const companyUser = action.payload?.data
        return {...state, companyUser}
      }
      case actionTypes.editCompanyUserSucceded: {
        return {...state,usersList: [...state.usersList.filter((el: any)=> el.id != action?.payload?.data?.id), action.payload.data]}
      }
      case actionTypes.getCompaniesSucceded: {
        const companiesList = action.payload?.data
        const company = {}
        return {...state, companiesList,company, first_page: action.payload?.meta?.first_page, last_page: action.payload?.meta?.last_page, current_page: action.payload?.meta?.current_page}
      }
      case actionTypes.getCompanySucceded: {
        const company = action.payload?.data
        return {...state, company}
      }
      case actionTypes.createCompanySucceded: {
        return {...state,companiesList: [...state.companiesList, action.payload.data]}
      }
      case actionTypes.editCompanySucceded: {
        return {...state,companiesList: [...state.companiesList.filter((el: any)=> el.id != action?.payload?.data?.id), action.payload.data]}
      }
      case actionTypes.deleteCompanySucceded: {
        return {...state,companiesList: [...state.companiesList.filter((el: any)=> el.id != action?.payload?.data?.id)]}
      }
      case actionTypes.getRolesSucceded: {
        const rolesList = action.payload?.data
        const role = {}
        return {...state, rolesList,role}
      }
      case actionTypes.getPermissionsSucceded: {
        const permissionsList = action.payload?.data
        return {...state, permissionsList}
      }
      case actionTypes.getRoleSucceded: {
        const role = action.payload?.data
        return {...state, role}
      }
      case actionTypes.createRoleSucceded: {
        return {...state,rolesList: [...state.rolesList, action.payload.data]}
      }
      case actionTypes.editRoleSucceded: {
        return {...state,rolesList: [...state.rolesList.filter((el: any)=> el.id != action?.payload?.data?.id), action.payload.data]}
      }
      case actionTypes.deleteRoleSucceded: {
        return {...state,rolesList: [...state.rolesList.filter((el: any)=> el.id != action?.payload?.data?.id)]}
      }
      default:
        return state
    }
  }
)

export const actions = {
  getCompanyUsersSucceded: (data : any) => ({type: actionTypes.getCompanyUsersSucceded, payload: data}),
  getCompanyUsers: (filter?: any)  => ({type: actionTypes.getCompanyUsers, payload: filter}),
  getCompanyUserSucceded: (data : any) => ({type: actionTypes.getCompanyUserSucceded, payload: data}),
  getCompanyUser: (data : any)  => ({type: actionTypes.getCompanyUser, payload: data}),
  editCompanyUser: (data : any) => ({type: actionTypes.editCompanyUser, payload: data}),
  editCompanyUserSucceded: (data : any) => ({type: actionTypes.editCompanyUserSucceded, payload: data}),
  createRoleSucceded: (data : any) => ({type: actionTypes.createRoleSucceded, payload: data}),
  createRole: (data : any, history : any) => ({type: actionTypes.createRole, payload: data, history:history}),
  editRole: (data : any, history : any) => ({type: actionTypes.editRole, payload: data, history:history}),
  editRoleSucceded: (data : any) => ({type: actionTypes.editRoleSucceded, payload: data}),
  deleteRole: (data : any) => ({type: actionTypes.deleteRole, payload: data}),
  getRoleSucceded: (data : any) => ({type: actionTypes.getRoleSucceded, payload: data}),
  getRole: (data : any)  => ({type: actionTypes.getRole, payload: data}),
  getRolesSucceded: (data : any) => ({type: actionTypes.getRolesSucceded, payload: data}),
  getRoles: () => ({type: actionTypes.getRoles}),
  getPermissionsSucceded: (data : any) => ({type: actionTypes.getPermissionsSucceded, payload: data}),
  getPermissions: () => ({type: actionTypes.getPermissions}),
  editCompany: (data : any) => ({type: actionTypes.editCompany, payload: data}),
  editCompanySucceded: (data : any) => ({type: actionTypes.editCompanySucceded, payload: data}),
  deleteCompany: (data : any) => ({type: actionTypes.deleteCompany, payload: data}),
  getCompanySucceded: (data : any) => ({type: actionTypes.getCompanySucceded, payload: data}),
  getCompany: (data : any)  => ({type: actionTypes.getCompany, payload: data}),
  getCompaniesSucceded: (data : any) => ({type: actionTypes.getCompaniesSucceded, payload: data}),
  getCompanies: (filter?: any) => ({type: actionTypes.getCompanies, payload: filter}),
  
  store: () => ({type: "def"}),
}

export function* saga() {
  yield takeEvery(actionTypes.getCompanyUsers, function* getCompanyUsersSaga(payload: any) {
    const {data} = yield getCompanyUsersApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getCompanyUsersSucceded(data))
  })
  yield takeEvery(actionTypes.getCompanyUser, function* getCompanyUserSaga(data: any) {
    const user : any = {} = yield getCompanyUserApi(data?.payload)
    yield put(actions.getCompanyUserSucceded(user))
  })
  yield takeEvery(actionTypes.editCompanyUser, function* editCompanyUserSaga(data: any) {
    const createdData :{} = yield editCompanyUser(data?.payload)
    yield put(actions.editCompanyUserSucceded(createdData))
  })
  yield takeEvery(actionTypes.getPermissions, function* getPermissionsSaga() {
    const {data} = yield getPermissionsApi()
    yield put(actions.getPermissionsSucceded(data))
  })
  yield takeEvery(actionTypes.getRoles, function* getRolesSaga() {
    const {data} = yield getRolesApi()
    yield put(actions.getRolesSucceded(data))
  })
  yield takeEvery(actionTypes.getRole, function* getRoleSaga(data: any) {
    const role:{} = yield getRoleApi(data?.payload?.id)
    yield put(actions.getRoleSucceded(role))
  })
  
  yield takeEvery(actionTypes.editRole, function* editRoleSaga(data: any) {
    const createdRole :{} = yield editRole(data?.payload)
    yield put(actions.editRoleSucceded(createdRole))
   setTimeout(() => {
    data?.history?.push('/crafted/pages/company/role/list')
   })
   })
   yield takeEvery(actionTypes.createRole, function* createRoleSaga(data: any) {
     const createdRole :{} = yield createRole(data?.payload)
     yield put(actions.createRoleSucceded(createdRole))
    setTimeout(() => {
     data?.history?.push('/crafted/pages/company/role/list')
    })
    })
    yield takeEvery(actionTypes.deleteRole, function* deleteRoleSaga(data: any) {
      yield deleteRole(data?.payload?.id)
      yield put(actions.getRoles())
    })
    yield takeEvery(actionTypes.getCompanies, function* getCompaniesSaga(payload: any) {
      const {data} = yield getCompaniesApi(payload?.payload?.page, payload?.payload?.filter)
      yield put(actions.getCompaniesSucceded(data))
    })
    yield takeEvery(actionTypes.getCompany, function* getCompanySaga(data: any) {
      const role:{} = yield getCompanyApi(data?.payload?.id)
      yield put(actions.getCompanySucceded(role))
    })
    
    yield takeEvery(actionTypes.editCompany, function* editCompanySaga(data: any) {
      const createdCompany :{} = yield editCompanyApi(data?.payload)
      yield put(actions.editCompanySucceded(createdCompany))
     })
      yield takeEvery(actionTypes.deleteCompany, function* deleteCompanySaga(data: any) {
        yield deleteCompanyApi(data?.payload?.id)
        yield put(actions.getCompanies())
      })
}

