import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as category from '../../app/modules/categories'
import * as user from '../../app/modules/users'
import * as company from '../../app/modules/company'

import * as data from '../../app/modules/data'
export const rootReducer = combineReducers({
  auth: auth.reducer,
  category: category.reducer,
  data: data.reducer,
  user: user.reducer,
  company: company.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([category.saga(),auth.saga(),data.saga(),user.saga(),company.saga()])
}
