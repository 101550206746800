/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useLocation, useParams} from 'react-router-dom'
import {RootState} from '../../../../../../setup'
import {KTSVG} from './../../../../../../_metronic/helpers'
import {DataTable} from './TablesWidget5'
import * as redux from '../../../redux/UserRedux'
import {getCandidateApplications, getCandidateFiles, getCandidateInterviews} from '../../../redux/service'
import moment from 'moment'

export function Overview() {
  const location = useLocation()
  const dispatch = useDispatch()
  const params: any = useParams()
  const [userFiles, setUserFiles] = useState<any>([])
  const [invitations, setInvitations] = useState<any>([])
  const [applications, setApplications] = useState<any>([])
  const user: any = useSelector<RootState>(({user}) => user.user)
  useEffect(() => {
    dispatch(redux.actions.getUser({id: params.id}))
    getCandidateFiles(params.id).then(({data: {data}}) => {
      setUserFiles(data)
    })
    getCandidateApplications(params.id).then(({data: {data}}) => {
      setApplications(data)
    })
    getCandidateInterviews(params.id).then(({data: {data}}) => {
      setInvitations(data)
    })
  }, [])
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Account Details</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {user.first_name + ' ' + user.last_name}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Contact Email</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{user.email || '-'}</span>

              {
                //<span className='badge badge-success'>Verified</span>
              }
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Birth Date</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{user.birthday || '-'}</span>

              {
                //<span className='badge badge-success'>Verified</span>
              }
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Cin</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{user.cin || '-'}</span>

              {
                //<span className='badge badge-success'>Verified</span>
              }
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Contact Phone</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{user.phone || '-'}</span>

              {
                //<span className='badge badge-success'>Verified</span>
              }
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Height</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{user.height || '-'}</span>

              {
                //<span className='badge badge-success'>Verified</span>
              }
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Disponibility</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{user.disponibility || '-'}</span>

              {
                //<span className='badge badge-success'>Verified</span>
              }
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Can work standing</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <div
                className='form-check form-switch fv-row'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
              >
                <input
                  className='form-check-input w-45px h-30px'
                  type='checkbox'
                  id='allowmarketing'
                  disabled={true}
                  checked={user.can_work_standing}
                />
                <label className='form-check-label'></label>
              </div>
            </div>
          </div>
          <label className='col-lg-4 fw-bold text-muted'>Files</label>
          <div className='card-body py-10'>
            <div className='table-responsive'>
              <table
                className='table align-middle table-row-dashed fw-bold text-gray-600 fs-6 gy-5'
                id='kt_table_customers_logs'
              >
                <thead>
                  <tr>
                    <th className='p-0 w-50px'>Type</th>
                    <th className='p-0 min-w-150px'>Name</th>
                    <th className='p-0 min-w-140px'>Upload Date</th>
                  </tr>
                </thead>
                <tbody>
                  {userFiles.length > 0 ? (
                    userFiles.map((file: any, index: any) => (
                      <tr key={index}>
                        <td className='min-w-200px'>
                          <div className='badge badge-light-info'>{file.type}</div>
                        </td>
                        <td>
                          <a
                            href={file?.url}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='fw-bold min-w-200px fs-3'
                          >
                            {file.name}
                          </a>
                        </td>
                        <td className='pe-0 text-start min-w-200px'>
                          {moment(file?.updated_at).format('MM-DD-YYYY')}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className='min-w-125px'></td>
                      <td className='min-w-200px'>No Files Provided</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          <DataTable
            data={invitations}
            title='Interview Invitations'
            className='card-xxl-stretch mb-5 mb-xl-10'
          />
        </div>
        <div className='col-xl-6'>
          <DataTable
            data={applications}
            title='List of Applications'
            className='card-xxl-stretch mb-5 mb-xl-10'
          />
        </div>
      </div>
    </>
  )
}
