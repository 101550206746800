/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Master Data Management'
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/dashboard'
        title='Master Data'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub
          to='/crafted/pages'
          title='Job Standards'
          fontIcon='bi-archive'
          hasBullet={true}
        >
          <AsideMenuItem
            to='/crafted/pages/categories/domain'
            title='Activity Domain'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/categories/department'
            title='Department'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/categories/unit' title='Unit' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/categories/process' title='Process' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/categories/poste' title='Poste' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to='/crafted/pages'
          title='Job Seekers Profile'
          fontIcon='bi-archive'
          hasBullet={true}
        >
          <AsideMenuItem to='/crafted/pages/data/skill' title='Skills' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/data/contract_type'
            title='Contract Types'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/data/university'
            title='Universities'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/data/diplome' title='Diplomes' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/data/speciality' title='Specialities' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/data/country' title='Countries' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/data/city' title='Cities' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/data/delegation' title='Delegations' hasBullet={true} />
<AsideMenuItem to='/crafted/pages/data/company' title='Companies' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to='/crafted/pages'
          title='Job Offer Detail'
          fontIcon='bi-archive'
          hasBullet={true}
        >
          <AsideMenuItem to='/crafted/pages/data/work_poste' title='Job Types' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/data/benefit' title='Benefits' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Users Management'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/crafted/pages/user/list' title='Users' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Companies Management'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/crafted/pages/company/role/list' title='Roles' hasBullet={true} />
        <AsideMenuItem
          to='/crafted/pages/company/user/list'
          title='Company Users'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/crafted/pages/company/company/list'
          title='Companies'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
    </>
  )
}
