import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Benefit} from './components/benefit/Benefit'
import {CreateBenefit} from './components/benefit/CreateBenefit'
import {EditBenefit} from './components/benefit/EditBenefit'

import {Skill} from './components/skill/Skill'
import {CreateSkill} from './components/skill/CreateSkill'
import {EditSkill} from './components/skill/EditSkill'

import {WorkPoste} from './components/workPoste/WorkPoste'
import {CreateWorkPoste} from './components/workPoste/CreateWorkPoste'
import {EditWorkPoste} from './components/workPoste/EditWorkPoste'

import {ContractType} from './components/contractType/ContractType'
import {CreateContractType} from './components/contractType/CreateContractType'
import {EditContractType} from './components/contractType/EditContractType'

import {JobTitle} from './components/jobTitle/JobTitle'
import {CreateJobTitle} from './components/jobTitle/CreateJobTitle'
import {EditJobTitle} from './components/jobTitle/EditJobTitle'

import {University} from './components/university/University'
import {CreateUniversity} from './components/university/CreateUniversity'
import {EditUniversity} from './components/university/EditUniversity'

import {Diplome} from './components/diplome/Diplome'
import {CreateDiplome} from './components/diplome/CreateDiplome'
import {EditDiplome} from './components/diplome/EditDiplome'

import {Country} from './components/country/Country'
import {CreateCountry} from './components/country/CreateCountry'
import {EditCountry} from './components/country/EditCountry'

import {Delegation} from './components/delegation/Delegation'
import {CreateDelegation} from './components/delegation/CreateDelegation'
import {EditDelegation} from './components/delegation/EditDelegation'

import {City} from './components/city/City'
import {CreateCity} from './components/city/CreateCity'
import {EditCity} from './components/city/EditCity'
import { Company } from './components/company/Company'
import { CreateCompany } from './components/company/CreateCompany'
import { EditCompany } from './components/company/EditCompany'
import { CreateSpeciality } from './components/speciality/CreateSpeciality'
import { EditSpeciality } from './components/speciality/EditSpeciality'
import { Speciality } from './components/speciality/Speciality'

export function DataPage() {
  const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Master Data',
      path: '/crafted/pages/data/skill',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <>
      <Switch>
        <Route path='/crafted/pages/data/benefit' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Benefits</PageTitle>
          <Benefit />
        </Route>
        <Route path='/crafted/pages/data/benefit/create' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Create Benefit</PageTitle>
          <CreateBenefit />
        </Route>
        <Route path='/crafted/pages/data/benefit/:id' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Edit Benefit</PageTitle>
          <EditBenefit />
        </Route>
        <Route path='/crafted/pages/data/skill' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Skills</PageTitle>
          <Skill />
        </Route>
        <Route path='/crafted/pages/data/skill/create' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Create Skill</PageTitle>
          <CreateSkill />
        </Route>
        <Route path='/crafted/pages/data/skill/:id' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Edit Skill</PageTitle>
          <EditSkill />
        </Route>
        <Route path='/crafted/pages/data/work_poste' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Job Types</PageTitle>
          <WorkPoste />
        </Route>
        <Route path='/crafted/pages/data/work_poste/create' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Create Job Type</PageTitle>
          <CreateWorkPoste />
        </Route>
        <Route path='/crafted/pages/data/work_poste/:id' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Edit Job Type</PageTitle>
          <EditWorkPoste />
        </Route>
        <Route path='/crafted/pages/data/contract_type' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Contract Types</PageTitle>
          <ContractType />
        </Route>
        <Route path='/crafted/pages/data/contract_type/create' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Create Contract Type</PageTitle>
          <CreateContractType />
        </Route>
        <Route path='/crafted/pages/data/contract_type/:id' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Edit Contract Type</PageTitle>
          <EditContractType />
        </Route>
        <Route path='/crafted/pages/data/job_title' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Job Titles</PageTitle>
          <JobTitle />
        </Route>
        <Route path='/crafted/pages/data/job_title/create' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Create Job Title</PageTitle>
          <CreateJobTitle />
        </Route>
        <Route path='/crafted/pages/data/job_title/:id' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Edit Job Title</PageTitle>
          <EditJobTitle />
        </Route>
        <Route path='/crafted/pages/data/company' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Companies</PageTitle>
          <Company />
        </Route>
        <Route path='/crafted/pages/data/company/create' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Create Company</PageTitle>
          <CreateCompany />
        </Route>
        <Route path='/crafted/pages/data/company/:id' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Edit Company</PageTitle>
          <EditCompany />
        </Route>
        <Route path='/crafted/pages/data/speciality' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Specialiities</PageTitle>
          <Speciality />
        </Route>
        <Route path='/crafted/pages/data/speciality/create' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Create Speciality</PageTitle>
          <CreateSpeciality />
        </Route>
        <Route path='/crafted/pages/data/speciality/:id' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Edit Speciality</PageTitle>
          <EditSpeciality />
        </Route>
        <Route path='/crafted/pages/data/university' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Universities</PageTitle>
          <University />
        </Route>
        <Route path='/crafted/pages/data/university/create' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Create University</PageTitle>
          <CreateUniversity />
        </Route>
        <Route path='/crafted/pages/data/university/:id' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Edit University</PageTitle>
          <EditUniversity />
        </Route>
        <Route path='/crafted/pages/data/diplome' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Diplomes</PageTitle>
          <Diplome />
        </Route>
        <Route path='/crafted/pages/data/diplome/create' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Create Diplome</PageTitle>
          <CreateDiplome />
        </Route>
        <Route path='/crafted/pages/data/diplome/:id' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Edit Diplome</PageTitle>
          <EditDiplome />
        </Route>
        <Route path='/crafted/pages/data/country' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Countries</PageTitle>
          <Country />
        </Route>
        <Route path='/crafted/pages/data/country/create' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Create Country</PageTitle>
          <CreateCountry />
        </Route>
        <Route path='/crafted/pages/data/country/:id' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Edit Country</PageTitle>
          <EditCountry />
        </Route>
        <Route path='/crafted/pages/data/delegation' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Delegations</PageTitle>
          <Delegation />
        </Route>
        <Route path='/crafted/pages/data/delegation/create' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Create Delegation</PageTitle>
          <CreateDelegation />
        </Route>
        <Route path='/crafted/pages/data/delegation/:id' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Edit Delegation</PageTitle>
          <EditDelegation />
        </Route>
        <Route path='/crafted/pages/data/city' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Cities</PageTitle>
          <City />
        </Route>
        <Route path='/crafted/pages/data/city/create' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Create City</PageTitle>
          <CreateCity />
        </Route>
        <Route path='/crafted/pages/data/city/:id' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Edit City</PageTitle>
          <EditCity />
        </Route>
        <Redirect from='/crafted/pages/data' exact={true} to='/crafted/pages/data/skill' />
      </Switch>
    </>
  )
}

export default DataPage
