import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as redux from '../../redux/CompanyRedux'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {RootState} from '../../../../../setup'
import {Button, Modal} from 'react-bootstrap-v5'
export function Company() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [filter, setFilter] = useState<any>({})
  const [companyToUpdate, setCompany] = useState({id: '', name: '', status: ''})
  const [open, setOpen] = useState(false)
  const last_page: any = useSelector<RootState>(({company}) => company.last_page)
  const [page, setPage] = useState<any>(1)
  const companies: any = useSelector<RootState>(({company}) => company.companiesList)
  const [category, setCategory] = useState('all')
  useEffect(() => {
    dispatch(redux.actions.getCompanies({filter: JSON.stringify(filter), page: page}))
  }, [page, filter])
  const confirmEdit = (company: any) => {
    dispatch(redux.actions.editCompany(company))
    setCompany({id: '', name: '', status: ''})
    setOpen(false)
  }
  const cancelEdit = () => {
    setOpen(false)
    dispatch(redux.actions.getCompanies({filter: JSON.stringify(filter), page: page}))
  }
  const goToAccount = (company: any) => {
    history.push('/crafted/pages/company/' + company.id + '/overview')
  }
  const handelFilterChange = (filterName: any, operand: any, filterValue: any) => {
    let newFilter = {[filterName]: {op: operand, value: filterValue}}
    if (!filterValue || filterValue == 'all') {
      setFilter((prevState: any) => {
        let temp = prevState
        delete temp[filterName]
        return {...temp}
      })
    } else {
      setFilter((prevState: any) => {
        return {...prevState, ...newFilter}
      })
    }
  }
  return (
    companies && (
      <>
        <div className='table-responsive'>
          {/* begin::Table */}
          <div className='col-lg-12 mb-10'>
            <div>
              {/* begin::Row */}
              <div
                className='row py-10 mx-10'
                style={{backgroundColor: 'white'}}
                data-kt-control='toggle-buttons'
              >
                <input
                  type='radio'
                  className='btn-check'
                  name='form-options'
                  checked={category === 'all'}
                  value='all'
                  id='kt_form_options_1'
                  onChange={(e) => {
                    setCategory(e.target.value)
                    handelFilterChange('status', '=', e.target.value)
                  }}
                />
                <label
                  className={`col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-125px ${
                    category === 'all' ? 'active' : ''
                  }`}
                  htmlFor='kt_form_options_1'
                >
                  <span className='text-gray-800 fw-bold'>All</span>
                </label>

                <input
                  type='radio'
                  className='btn-check'
                  name='form-options'
                  value='active'
                  id='kt_form_options_2'
                  checked={category === 'active'}
                  onChange={(e) => {
                    setCategory(e.target.value)
                    handelFilterChange('status', '=', e.target.value)
                  }}
                />
                <label
                  className={`col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-125px ${
                    category === 'active' ? 'active' : ''
                  }`}
                  htmlFor='kt_form_options_2'
                >
                  <span className='text-gray-800 fw-bold'>Active</span>
                </label>

                <input
                  type='radio'
                  className='btn-check'
                  name='form-options'
                  value='pending'
                  id='kt_form_options_3'
                  checked={category === 'pending'}
                  onChange={(e) => {
                    setCategory(e.target.value)
                    handelFilterChange('status', '=', e.target.value)
                  }}
                />
                <label
                  className={`col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-125px ${
                    category === 'pending' ? 'active' : ''
                  }`}
                  htmlFor='kt_form_options_3'
                >
                  <span className='text-gray-800 fw-bold'>Pending</span>
                </label>
              </div>
              {/* end::Row */}
            </div>
          </div>
          <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='border-0'>
                <th className='p-0 w-50px'>Image</th>
                <th className='p-0 min-w-100px'>Name</th>
                <th className='p-0 min-w-100px'>Domain Of Activity</th>
                <th className='p-0 min-w-60px'>Status</th>
                <th className='p-0 min-w-60px'>Phone</th>
                <th className='p-0 min-w-60px'>Website</th>
                <th className='p-0 min-w-80px'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {companies.map((company: any, i: any) => {
                return (
                  <tr key={i}>
                    <td
                      onClick={() => {
                        goToAccount(company)
                      }}
                    >
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img src={company.image_url} className='h-50 align-self-center' alt='' />
                        </span>
                      </div>
                    </td>
                    <td
                      onClick={() => {
                        goToAccount(company)
                      }}
                    >
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        {company.name}
                      </a>
                      <span className='text-muted fw-bold d-block'>{company.email || '-'}</span>
                    </td>
                    <td
                      onClick={() => {
                        goToAccount(company)
                      }}
                      className='text-start text-muted fw-bold'
                    >
                      {company.domain_of_activity || '-'}
                    </td>
                    <td className='text-start'>
                      {!company.status && (
                        <span className='badge badge-light-primary'>inactive</span>
                      )}
                      {company.status == 'active' && (
                        <span className='badge badge-light-success'>{company.status}</span>
                      )}
                      {company.status == 'pending' && (
                        <span className='badge badge-light-warning'>{company.status}</span>
                      )}
                      {company.status == 'blocked' && (
                        <span className='badge badge-light-danger'>{company.status}</span>
                      )}
                    </td>
                    <td
                      onClick={() => {
                        goToAccount(company)
                      }}
                      className='text-start text-muted fw-bold'
                    >
                      {company.phone || '-'}
                    </td>

                    <td
                      onClick={() => {
                        goToAccount(company)
                      }}
                      className='text-start text-muted fw-bold'
                    >
                      {company.website || '-'}
                    </td>
                    <td className='text-start'>
                      <div className='row mb-0'>
                        <div className='col-lg-8 d-flex align-items-center'>
                          <div>
                            <select
                              className='form-select form-select-solid'
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              value=''
                              onChange={(event) => {
                                if (event.target.value) {
                                  company.status = event.target.value
                                  setCompany(company)
                                  setOpen(true)
                                }
                              }}
                            >
                              <option disabled={true} value='' selected={true}>
                                Select action
                              </option>
                              {company.status != 'active' && (
                                <option value='active' selected={false}>
                                  Activate
                                </option>
                              )}
                              {company.status != 'pending' && (
                                <option value='pending' selected={false}>
                                  Put on Hold
                                </option>
                              )}
                              {company.status != 'blocked' && (
                                <option value='blocked' selected={false}>
                                  Block
                                </option>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          <div className='d-flex flex-stack flex-wrap pt-10' style={{float: 'right'}}>
            <ul className='pagination'>
              {last_page &&
                Array.from(Array(last_page), (_, i) => i + 1).map((number: any) => {
                  return (
                    <li key={number} className={`page-item ${number == page ? 'active' : ''}`}>
                      <a onClick={() => setPage(number)} className='page-link'>
                        {number}
                      </a>
                    </li>
                  )
                })}
            </ul>
          </div>
          <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Update Company</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  {companyToUpdate.status == 'active' && (
                    <p>Are you sure you want to activate "{companyToUpdate?.name}" ?</p>
                  )}
                  {companyToUpdate.status == 'pending' && (
                    <p>Are you sure you want to put on hold "{companyToUpdate?.name}" ?</p>
                  )}
                  {companyToUpdate.status == 'blocked' && (
                    <p>Are you sure you want to block "{companyToUpdate?.name}" ?</p>
                  )}
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-light'
                    data-bs-dismiss='modal'
                    onClick={(e) => {
                      cancelEdit()
                    }}
                  >
                    Close
                  </button>
                  <button
                    type='button'
                    data-bs-dismiss='modal'
                    onClick={(e) => {
                      confirmEdit(companyToUpdate)
                    }}
                    className='btn btn-primary'
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Modal show={open} onHide={cancelEdit}>
            <Modal.Header closeButton>
              <Modal.Title>Update Company</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {companyToUpdate.status == 'active' && (
                <p>Are you sure you want to activate "{companyToUpdate?.name}" ?</p>
              )}
              {companyToUpdate.status == 'pending' && (
                <p>Are you sure you want to put on hold "{companyToUpdate?.name}" ?</p>
              )}
              {companyToUpdate.status == 'blocked' && (
                <p>Are you sure you want to block "{companyToUpdate?.name}" ?</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={cancelEdit}>
                Close
              </Button>
              <Button
                variant='primary'
                onClick={(e) => {
                  confirmEdit(companyToUpdate)
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end::Table */}
        </div>
      </>
    )
  )
}
