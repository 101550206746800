import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { Overview } from './components/accounts/components/Overview'
import { CompanyOverview } from './components/accounts/components/CompanyOverview'
import { Settings } from './components/accounts/components/settings/Settings'
import { CreateRole } from './components/role/CreateRole'
import { EditRole } from './components/role/EditRole'
import { Role } from './components/role/Role'
import {User} from './components/user/User'
import {Company} from './components/company/Company'


export function CompanyPage() {
  const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'Company',
      path: '/crafted/pages/company/role/list',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <>
      <Switch>
        <Route path='/crafted/pages/company/role/list' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs} >Roles</PageTitle>
          <Role />
        </Route>
        <Route path='/crafted/pages/company/role/create' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Create Role</PageTitle>
          <CreateRole />
        </Route>
        <Route path='/crafted/pages/company/role/edit/:id' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Edit Role</PageTitle>
          <EditRole />
        </Route>

        <Route path='/crafted/pages/company/company/list' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Companies</PageTitle>
          <Company />
        </Route>
        <Route path='/crafted/pages/company/user/list' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Company Users</PageTitle>
          <User />
        </Route>
        <Route path='/crafted/pages/company/user/:id/overview' exact={true}>
          <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>
          <Overview />
        </Route>
        <Route path='/crafted/pages/company/:id/overview' exact={true}>
          <PageTitle breadcrumbs={profileBreadCrumbs}>Company Overview</PageTitle>
          <CompanyOverview />
        </Route>
        <Redirect from='/crafted/pages/company' exact={true} to='/crafted/pages/company/user/list' />
      </Switch>
    </>
  )
}

export default CompanyPage
