/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from './../../../../../../_metronic/helpers'
import moment from 'moment'

type Props = {
  className: string
  data: any
  title: string
}

const DataTable: React.FC<Props> = ({className, data, title}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 min-w-100px'>Company</th>
                    <th className='p-0 min-w-50px'>Date</th>
                    <th className='p-0 min-w-50px'>Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data &&
                    data.map((el: any, i: number) => {
                      return <tr key={i}>
                        <td>
                          <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                            {el.company?.name || '-'}
                          </a>
                        </td>
                        <td className='text-start text-muted fw-bold'>
                          {moment(el.date).format('DD-MM-YYYY') || '-'}
                        </td>
                        <td className='text-start'>
                          {el.status == 'accepted' && (
                            <span className='badge badge-light-success'>{el.status}</span>
                          )}
                          {el.status == 'pending' && (
                            <span className='badge badge-light-warning'>{el.status}</span>
                          )}
                          {el.status == 'refused' && (
                            <span className='badge badge-light-danger'>{el.status}</span>
                          )}
                        </td>
                      </tr>
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {DataTable}
