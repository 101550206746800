import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as redux from '../../redux/DataRedux'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {RootState} from '../../../../../setup'
export function JobTitle() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [dataToDelete, setDataToDelete] = useState({id: null, name: '', name_ar: '', name_fr:''})
  const jobTitles: any = useSelector<RootState>(({data}) => data.jobTitlesList)
  useEffect(() => {
    dispatch(redux.actions.getJobTitles())
  }, [])
  const deleteData = (data: any) => {
    setDataToDelete(data)
  }
  const editData = (data: any) => {
    history.push('/crafted/pages/data/job_title/' + data.id)
  }
  const confirmDeleteData = (data: any) => {
    dispatch(redux.actions.deleteData(data))
  }
  return (
    jobTitles && (
      <>
        <div className='table-responsive'>
          {/* begin::Table */}
          <a
            href='/crafted/pages/data/job_title/create'
            className='btn btn-sm btn-primary'
            id='kt_toolbar_primary_button'
            style={{float: 'right', backgroundColor: '#87e8c5'}}
          >
            Create
          </a>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Name</th>
                <th className='min-w-150px'>Arabic Name</th>
<th className='min-w-150px'>French Name</th>
                <th className='min-w-140px'>Type</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {jobTitles.map((jobTitle: any, i: any) => {
                return (
                  <tr key={i}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {jobTitle.name}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {jobTitle.name_ar}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-bold'>{jobTitle.type}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <a
                          onClick={(e) => {
                            editData(jobTitle)
                          }}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </a>
                        <a
                          onClick={(e) => {
                            deleteData(jobTitle)
                          }}
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_1'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Delete Job Title</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <p>Are you sure you want to delete "{dataToDelete?.name}"</p>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                    Close
                  </button>
                  <button
                    type='button'
                    data-bs-dismiss='modal'
                    onClick={(e) => {
                      confirmDeleteData(dataToDelete)
                    }}
                    className='btn btn-primary'
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* end::Table */}
        </div>
      </>
    )
  )
}
