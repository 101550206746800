import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as redux from '../../redux/DataRedux'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {RootState} from '../../../../../setup'
import * as XLSX from 'xlsx'
import Swal from 'sweetalert2'
import {uploadDataApi} from '../../redux/service'
export function Company() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [dataToDelete, setDataToDelete] = useState({id: null, name: '', name_ar: '', name_fr: ''})
  const [category, setCategory] = useState('all')
  const [entries, setEntries] = useState([])
  const [filter, setFilter] = useState<any>({type: {op: '=', value: 'company'}})
  const last_page: any = useSelector<RootState>(({data}) => data.last_page)
  const [page, setPage] = useState<any>(1)
  const companies: any = useSelector<RootState>(({data}) => data.companiesDataList)
  useEffect(() => {
    dispatch(redux.actions.getCompaniesData({filter: JSON.stringify(filter), page: page}))
  }, [filter, page])
  const deleteData = (data: any) => {
    setDataToDelete(data)
  }
  const editData = (data: any) => {
    history.push('/crafted/pages/data/company/' + data.id)
  }
  const confirmDeleteData = (data: any) => {
    dispatch(redux.actions.deleteData(data))
  }
  const handelFilterChange = (filterName: any, operand: any, filterValue: any) => {
    let newFilter = {[filterName]: {op: operand, value: filterValue}}
    if (!filterValue || filterValue == 'all') {
      setFilter((prevState: any) => {
        let temp = prevState
        delete temp[filterName]
        return {...temp}
      })
    } else {
      setFilter((prevState: any) => {
        return {...prevState, ...newFilter}
      })
    }
  }
  const confirmUpload = (data: any) => {
    if (data && data.length > 0)
      data = data.map((el: any, index: number) => {
        return {type: 'company', name: el.name, name_ar: el.name_ar, name_fr: el.name_fr}
      })

    setTimeout(() => {
      uploadDataApi({data})
        .then(() => {
          Swal.fire('Good!', 'You successfully uploaded the list of Companies', 'success').then(
            () => {
              dispatch(redux.actions.getCompaniesData())
            }
          )
        })
        .catch(() => {
          Swal.fire('Error!', 'You failed to upload the list of Companies !', 'error')
        })
    }, 1000)
  }
  const onChange = (e: any) => {
    const [file] = e.target.files
    const reader = new FileReader()

    reader.onload = (evt) => {
      const bstr = evt && evt.target && evt.target.result
      const wb = XLSX.read(bstr, {type: 'binary'})
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data: any = XLSX.utils.sheet_to_json(ws)
      setEntries(data)
    }
    reader.readAsBinaryString(file)
  }
  return (
    companies && (
      <>
        <div className='table-responsive'>
          {/* begin::Table */}

          <a
            href='/crafted/pages/data/company/create'
            className='btn btn-sm btn-primary'
            id='kt_toolbar_primary_button'
            style={{float: 'right', backgroundColor: '#87e8c5'}}
          >
            Create
          </a>
          <div>
            <label className='form-label'>Upload a list of Companies</label>
            <input
              className='form-control form-control-lg'
              onChange={onChange}
              id='formFileLg'
              type='file'
            />
            <button
              className='btn btn-sm btn-primary'
              style={{float: 'right'}}
              onClick={() => confirmUpload(entries)}
              disabled={!(entries.length > 0)}
            >
              Upload
            </button>
          </div>

          {/* begin::Section */}
          <div>
            {/* begin::Row */}
            <div
              className='row py-10'
              style={{backgroundColor: 'white'}}
              data-kt-control='toggle-buttons'
            >
              <input
                type='radio'
                className='btn-check'
                name='form-options'
                checked={category === 'all'}
                value='all'
                id='kt_form_options_1'
                onChange={(e) => {
                  setCategory(e.target.value)
                  handelFilterChange('status', '=', e.target.value)
                }}
              />
              <label
                className={`col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-125px ${
                  category === 'all' ? 'active' : ''
                }`}
                htmlFor='kt_form_options_1'
              >
                <span className='text-gray-800 fw-bold'>All</span>
              </label>

              <input
                type='radio'
                className='btn-check'
                name='form-options'
                value='active'
                id='kt_form_options_2'
                checked={category === 'active'}
                onChange={(e) => {
                  setCategory(e.target.value)
                  handelFilterChange('status', '=', e.target.value)
                }}
              />
              <label
                className={`col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-125px ${
                  category === 'active' ? 'active' : ''
                }`}
                htmlFor='kt_form_options_2'
              >
                <span className='text-gray-800 fw-bold'>Active</span>
              </label>

              <input
                type='radio'
                className='btn-check'
                name='form-options'
                value='pending'
                id='kt_form_options_3'
                checked={category === 'pending'}
                onChange={(e) => {
                  setCategory(e.target.value)
                  handelFilterChange('status', '=', e.target.value)
                }}
              />
              <label
                className={`col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-125px ${
                  category === 'pending' ? 'active' : ''
                }`}
                htmlFor='kt_form_options_3'
              >
                <span className='text-gray-800 fw-bold'>Pending</span>
              </label>
            </div>
            {/* end::Row */}
          </div>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Name</th>
                <th className='min-w-150px'>Arabic Name</th>
                <th className='min-w-150px'>French Name</th>
                <th className='min-w-140px'>Status</th>
                <th className='min-w-140px'>User</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {companies.map((company: any, i: any) => {
                return (
                  <tr key={i}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {company.name}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {company.name_ar}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {company.name_fr}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          {company.status == 'active' && (
                            <span className='badge badge-light-success'>{company.status}</span>
                          )}
                          {company.status == 'pending' && (
                            <span className='badge badge-light-warning'>{company.status}</span>
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {company.candidate
                              ? company.candidate?.first_name + ' ' + company.candidate?.last_name
                              : company.user
                              ? company.user?.company?.name : '-'}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <a
                          onClick={(e) => {
                            editData(company)
                          }}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </a>
                        <a
                          onClick={(e) => {
                            deleteData(company)
                          }}
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_1'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          <div className='d-flex flex-stack flex-wrap pt-10' style={{float: 'right'}}>
            <ul className='pagination'>
              {last_page &&
                Array.from(Array(last_page), (_, i) => i + 1).map((number: any) => {
                  return (
                    <li key={number} className={`page-item ${number == page ? 'active' : ''}`}>
                      <a onClick={() => setPage(number)} className='page-link'>
                        {number}
                      </a>
                    </li>
                  )
                })}
            </ul>
          </div>
          <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Delete Company</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <p>Are you sure you want to delete "{dataToDelete?.name}"</p>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                    Close
                  </button>
                  <button
                    type='button'
                    data-bs-dismiss='modal'
                    onClick={(e) => {
                      confirmDeleteData(dataToDelete)
                    }}
                    className='btn btn-primary'
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* end::Table */}
        </div>
      </>
    )
  )
}
