import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import * as redux from '../../redux/CompanyRedux'
import {useHistory, useParams} from 'react-router-dom'
export function EditRole(props: any) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [permissionsTitles, setPermissionsTitles]: any = useState([])
  const [permissionsAux, setPermissionsAux]: any = useState([])
  const permissions: any = useSelector<RootState>(({company}) => company.permissionsList)
  const role: any = useSelector<RootState>(({company}) => company.role)
  const params: any = useParams()
  useEffect(() => {
    dispatch(redux.actions.getPermissions())
    dispatch(redux.actions.getRole({id: params.id}))
  }, [])
  useEffect(() => {
    if (permissions?.length > 0) {
      let aux: any = []
      permissions.forEach((permission: any, index: any) => {
        if (index == 0)
          setPermissionsAux(
            permissions?.filter(
              (el: any) => el?.name?.split('.')[0] == permission?.name?.split('.')[0]
            )
          )
        if (aux?.indexOf(permission?.name?.split('.')[0]) < 0)
          aux.push(permission?.name?.split('.')[0])
      })
      setPermissionsTitles(aux)
    }
  }, [permissions])
  const selectTitle = (title: any) => {
    setPermissionsAux(permissions?.filter((el: any) => el?.name?.split('.')[0] == title))
  }
  const handleSubmit = (values: any, e: any) => {
    e.preventDefault()
    if (values.permissions?.length > 0)
      values.permissions = values.permissions.map((el: any) => el.id)
    dispatch(redux.actions.editRole(values, history))
  }
  return role && role.name ? (
    <>
      <Formik
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(true)
        }}
        initialValues={{
          id: role ? role.id || null : null,
          name: role ? role.name || '' : '',
          permissions: role ? role.permissions || [] : [],
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
        })}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleReset,
          setSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <form className='form'>
            <div className='w-100'>
              {/*begin::Heading */}
              {/*begin::Heading */}

              {/*begin::Form Group */}
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Role Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name'
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='card-body border-top px-9 pt-3 pb-4'>
              <div className='table-responsive'>
                <table className='table table-row-dashed border-gray-300 align-middle gy-6'>
                  <tbody className='fs-6 fw-bold'>
                    <tr style={{display: 'grid'}}>
                      <td className='min-w-250px fs-4 fw-bolder'>Permissions</td>
                      <td className='min-w-250px fs-4 fw-bolder'>
                        <div className='d-flex align-items-start'>
                          <div
                            className='nav min-w-250px flex-column nav-pills me-3'
                            id='v-pills-tab'
                            role='tablist'
                            aria-orientation='vertical'
                          >
                            {permissionsTitles &&
                              permissionsTitles?.map((title: any, index: any) => {
                                return (
                                  <button
                                    className={`nav-link ${index == 0 ? 'active' : ''}`}
                                    key={title}
                                    id='v-pills-home-tab'
                                    data-bs-toggle='pill'
                                    data-bs-target='#v-pills-home'
                                    type='button'
                                    role='tab'
                                    aria-controls='v-pills-home'
                                    aria-selected='true'
                                    onClick={() => {
                                      selectTitle(title)
                                    }}
                                  >
                                    {title}
                                  </button>
                                )
                              })}
                          </div>
                          <div className='tab-content min-w-450px' id='v-pills-tabContent'>
                            <div
                              className='tab-pane fade show active px-20'
                              id='v-pills-home'
                              role='tabpanel'
                              aria-labelledby='v-pills-home-tab'
                            >
                              {permissionsAux &&
                                permissionsAux.map((permission: any, i: number) => {
                                  return (
                                    <div key={i} className='form-check form-check-solid'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='kt_settings_notification_email'
                                        checked={
                                          values.permissions.find(
                                            (el: any) => el.id == permission.id
                                          ) || false
                                        }
                                        onChange={(event) => {
                                          let aux = values.permissions
                                          if (event.target.checked) aux.push(permission)
                                          else aux = aux.filter((el: any) => el.id != permission.id)
                                          setFieldValue('permissions', aux)
                                        }}
                                      />
                                      <label className='form-check-label ps-2'>
                                        {permission?.name?.split('.')[1]}
                                      </label>
                                    </div>
                                  )
                                })}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <button
              className='btn btn-primary'
              style={{backgroundColor: '#87e8c5'}}
              onClick={(e) => {
                setSubmitting(true)
                handleSubmit(values, e)
              }}
              type='submit'
            >
              Submit
            </button>
          </form>
        )}
      </Formik>
    </>
  ) : (
    <></>
  )
}
